<template>
  <a-drawer :width="500" :visible="visible" @close="handleClose" :title="$t('orderInfo.title')">
    <a-form-model
      ref="orderForm"
      :model="form"
      :rules="rules"
      :label-col="formSet.labelCol"
      :wrapper-col="formSet.wrapperCol"
      class="pt-4 pb-4 pr-4 pl-4"
    >
      <a-form-model-item :label="$t('orderInfo.file')" :labelAlign="formSet.labelAlign" required>
        <NeoUploadButton
          :title="$t('orderInfo.upload_again')"
          v-model="form.sourceFileList"
          sortType="unshift"
          :showNote="false"
          :showFileList="true"
          :showIcon="false"
          :accept="acceptMap.orderSourceFile"
          :maxSize="500"
          :multiple="true"
          :maxLength="20"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('orderInfo.order_name')" :labelAlign="formSet.labelAlign" required prop="name">
        <a-input v-model="form.name" :placeholder="$t('orderInfo.order_name')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('orderInfo.line')" :labelAlign="formSet.labelAlign" required prop="productLineId">
        <a-select v-model="form.productLineId" :placeholder="$t('orderInfo.line')">
          <a-select-option v-for="item in productLines" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('orderInfo.source')" :labelAlign="formSet.labelAlign" required prop="sourceCode">
        <a-select
          allowClear
          show-search
          :filter-option="langFilterOption"
          v-model="form.sourceCode"
          :placeholder="$t('orderInfo.source')"
          @change="onLangSourceChange"
        >
          <a-select-option v-for="(item, index) in sourceCodeList" :key="index" :value="item.code">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('orderInfo.target')"
        :labelAlign="formSet.labelAlign"
        required
        prop="targetCodeList"
      >
        <a-select
          allowClear
          show-search
          :filter-option="langFilterOption"
          v-model="form.targetCodeList"
          :placeholder="$t('orderInfo.target')"
          mode="multiple"
          @change="onLangTargetChange"
        >
          <a-select-option v-for="(item, index) in targetCodeList" :key="index" :value="item.code">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('orderInfo.service_type')"
        :labelAlign="formSet.labelAlign"
        required
        prop="serviceType"
      >
        <a-row>
          <a-col :span="15">
            <a-select v-model="form.serviceType" :placeholder="$t('orderInfo.service_type')">
              <a-select-option v-for="(item, index) in serviceTypeList" :key="index" :value="item.id">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="9" class="just-fe">
            <a-checkbox v-model="form.composeType">
              {{ $t('newOrder.fine') }}
            </a-checkbox>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item :label="$t('orderInfo.supplier')" :labelAlign="formSet.labelAlign" required prop="supplierId">
        <a-row>
          <a-col :span="22">
            <a-select v-model="form.supplierId" :placeholder="$t('orderInfo.supplier')" disabled>
              <a-select-option v-for="item in supplierList" :key="item.supEntityId" :value="item.supEntityId">
                {{ item.supEntityName }}
              </a-select-option>
            </a-select>
            <div style="color: red; line-height: 25px" v-if="supplierTipShow">
              {{
                $t('validate.supplier_info', {
                  s: getCodeName(form.sourceCode),
                  t: getCodesName(invalidTargetCodeList),
                  documentType: getDocTypeName(form.documentType),
                  serviceType: getTypeName(form.serviceType),
                })
              }}
            </div>
          </a-col>
          <a-col :span="2" class="pl-3">
            <a-tooltip placement="right">
              <template slot="title">
                <span>{{ $t('customerConfig.tip_select_recommend3') }}</span>
              </template>
              <img class="tip-icon" src="@/assets/image/tip-icon.png" alt="" />
            </a-tooltip>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('orderInfo.order_delivery')"
        :labelAlign="formSet.labelAlign"
        required
        prop="dueDate"
      >
        <a-date-picker
          style="width: 100%"
          allowCler
          valueFormat="YYYY-MM-DD HH:mm:00"
          v-model="form.dueDate"
          :showTime="timePickerOption"
          :format="$t('dateFormat.a')"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('orderInfo.order_note')" :labelAlign="formSet.labelAlign" prop="remark">
        <a-textarea
          v-model="form.remark"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          :placeholder="$t('orderInfo.order_note')"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('orderInfo.order_attachment')" :labelAlign="formSet.labelAlign">
        <NeoUploadButton
          :title="$t('orderInfo.upload_again')"
          v-model="form.attachmentFileList"
          sortType="unshift"
          :showNote="false"
          :showFileList="true"
          :showIcon="false"
          :accept="acceptMap.orderSourceFile"
          :maxSize="500"
          :multiple="true"
          :maxLength="5"
        />
      </a-form-model-item>
    </a-form-model>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button type="primary" @click="handleConfirm" :disabled="disabled">
        {{ $t('modifyPassword.confirm_modify') }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import { mapState } from 'vuex'
import NeoUploadButton from '@/components/upload/NeoUploadButton'

const { validate, acceptMap } = window.$g

export default {
  name: 'ModifyOrderDrawer',
  components: {
    NeoUploadButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 订单详情
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      acceptMap,
      formSet: {
        // span: 8,
        labelAlign: 'right',
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      form: {
        sourceFileList: [],
        name: undefined,
        sourceCode: undefined,
        supplierId: undefined,
        serviceType: undefined,
        documentType: undefined,
        dueDate: undefined,
        remark: undefined,
        productLineId: undefined,
        targetCodeList: undefined,
        composeType: undefined,
        attachmentFileList: [],
      },
      // 表单验证
      rules: {
        productLineId: [validate.required],
        dueDate: [validate.required, validate.earlier_time],
        sourceCode: [validate.required],
        targetCodeList: [validate.required],
        name: [validate.required, validate.length_3_50],
        documentType: [validate.required],
        serviceType: [validate.required],
        remark: [validate.size_1000],
        supplierId: [validate.required],
      },
      // 产品线列表
      productLines: [],
      // 供应商列表
      supplierList: [],
      timePickerOption: {
        format: 'HH:mm',
      },
      disabled: true,
      // 服务类型列表
      serviceTypeList: [],
      // 文档类型列表
      documentTypeList: [],
      form_json_init: '',
      form_json: '',
      sourceCodeList: [],
      targetCodeList: [],
      LangPair: [],
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
      langList: (state) => state.langList,
      industryList: (state) => state.industryList,
    }),
    invalidTargetCodeList() {
      const f = this.form
      let targetCodeList = []
      this.form.targetCodeList?.forEach((item) => {
        if (this.langList.some((it) => it.code == item)) {
          targetCodeList.push(item)
        } else {
          targetCodeList.push(this.langList.find((_) => _.name == item)?.code)
        }
      })
      f.targetCodeList = targetCodeList
      const needShowCheck = !!(
        f.serviceType &&
        f.documentType &&
        f.supplierId &&
        f.sourceCode &&
        f.targetCodeList.length
      )
      if (!needShowCheck) return []
      if (!this.LangPair) return [...f.targetCodeList]
      const validTarget = this.LangPair.filter((p) => p.serviceId === f.serviceType && p.documentId === f.documentType)
        .filter((p) => p.sourceCode === f.sourceCode)
        .filter((p) => f.targetCodeList.includes(p.targetCode))
        .map((p) => p.targetCode)
      return f.targetCodeList.filter((t) => !validTarget.includes(t))
    },
    supplierTipShow() {
      return this.invalidTargetCodeList.length > 0
    },
  },
  watch: {
    visible(v) {
      if (v) {
        this.form = { ...this.info }
        this.form.sourceFileList = []
        this.info.sourceFileList?.forEach((_) => {
          this.form.sourceFileList.push({
            ..._,
            uid: _.id,
          })
        })
        this.form.attachmentFileList = []
        this.info.attachmentFileList?.forEach((_) => {
          this.form.attachmentFileList.push({
            ..._,
            uid: _.id,
          })
        })
        this.form.targetCodeList = this.info.targetCodeList?.split(',')
        this.form.composeType = this.info.composeType ? true : false

        this.getOrderPairInfo()
        this.getCusProductLineList()
        this.getCusServiceTypeList()
        this.getCusDocumentTypeList()
        this.getSupList()
      } else {
        this.form = {}
        this.form.sourceFileList = []
        this.form.attachmentFileList = []
        this.form.targetCodeList = []
        this.$refs.orderForm.clearValidate()
        this.disabled = true
      }
    },
    form: {
      deep: true,
      handler(v) {
        this.form_json = JSON.stringify(v)
      },
    },
    form_json(v) {
      const bool =
        this.form.sourceFileList.length > 0 &&
        this.form.name &&
        this.form.productLineId &&
        this.form.sourceCode &&
        this.form.targetCodeList &&
        this.form.serviceType &&
        this.form.supplierId
      if (v != this.form_json_init && this.visible && bool) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
  },
  methods: {
    getCodeName(code = '') {
      let source = ''
      if (this.langList.some((_) => _.code == code)) {
        source = code
      } else {
        source = this.langList?.find((_) => _.name == code)?.code
      }
      return this.$store.getters['app/getLangNameByCode'](source) || '---'
    },
    getCodesName(codes = []) {
      const result = codes.map((code) => {
        return this.$store.getters['app/getLangNameByCode'](code)
      })
      return result || '---'
    },
    getTypeName(id = '') {
      return this.serviceTypeList.find((item) => item.id === id)?.label
    },
    getDocTypeName(id = '') {
      return this.documentTypeList.find((item) => item.id === id)?.label
    },

    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    onLangSourceChange(code) {
      if (code) {
        const lang_pair = this.LangPair.filter((_) => _.sourceCode == code)
        const targetCodeList = Array.from(new Set(lang_pair.map((_) => _.targetCode)))

        let targetList = []
        this.langList?.forEach((_) => {
          if (targetCodeList?.includes(_.code)) {
            targetList.push({
              ..._,
            })
          }
        })
        this.targetCodeList = targetList
      }
    },
    onLangTargetChange(codes) {
      if (codes.length > 0) {
        let lang_pair = []
        this.LangPair?.forEach((_) => {
          if (codes.includes(_.targetCode)) {
            lang_pair.push({
              ..._,
            })
          }
        })
        const sourceCodeList = Array.from(new Set(lang_pair.map((_) => _.sourceCode)))
        let sourceList = []
        this.langList?.forEach((_) => {
          if (sourceCodeList?.includes(_.code)) {
            sourceList.push({
              ..._,
            })
          }
        })
        this.sourceCodeList = sourceList
      }
    },

    // 获取有效的语言对
    async getOrderPairInfo() {
      try {
        const data = await this.$http('getOrderPairInfo')
        this.LangPair = data.supPairMap[this.info.supplierId]
        const sourceCodeList = Array.from(new Set(this.LangPair.map((_) => _.sourceCode)))
        const targetCodeList = Array.from(new Set(this.LangPair.map((_) => _.targetCode)))
        let sourceList = []
        let targetList = []
        this.langList?.forEach((_) => {
          if (sourceCodeList?.includes(_.code)) {
            sourceList.push({
              ..._,
            })
          }
          if (targetCodeList?.includes(_.code)) {
            targetList.push({
              ..._,
            })
          }
        })
        this.sourceCodeList = sourceList
        this.targetCodeList = targetList

        if (!this.sourceCodeList?.some((_) => _.code == this.form.sourceCode)) {
          this.form.sourceCode = this.langList?.find((_) => _.code == this.info.sourceCode)?.name
        }

        let target = []
        this.form.targetCodeList?.forEach((item) => {
          if (this.targetCodeList?.some((_) => _.code == item)) {
            target.push(item)
          } else {
            target.push(this.langList?.find((_) => _.code == item)?.name)
          }
        })
        this.form.targetCodeList = target

        this.form_json_init = JSON.stringify(this.form)
        this.form_json = JSON.stringify(this.form)
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 客户产品线表-列表
    async getCusProductLineList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusProductLineList', {
          entityId: agencyId,
        })
        this.productLines = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 客户服务类型表-列表
    async getCusServiceTypeList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusMaterialList', { entityId: agencyId, type: 1 })
        this.serviceTypeList = data.filter((_) => _.visibleOnPlace != 0)
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 客户文档类型表-列表
    async getCusDocumentTypeList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusMaterialList', { entityId: agencyId, type: 2 })
        this.documentTypeList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 获取供应商列表
    async getSupList() {
      try {
        const { sourceCode, targetCodeList, productLineId: supProductLineId } = this.form
        this.supplierList = await this.$http('getSupList', { sourceCode, targetCodeList, supProductLineId })
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    handleClose() {
      this.form.sourceFileList = []
      this.form.attachmentFileList = []

      this.$emit('close', false)
    },
    // 确认修改
    handleConfirm() {
      try {
        const params = {
          ...this.form,
        }
        params.composeType = this.form.composeType ? 1 : 0

        let sourceCode = ''
        if (this.langList.some((_) => _.code == this.form.sourceCode)) {
          sourceCode = this.form.sourceCode
        } else {
          sourceCode = this.langList?.find((_) => _.name == this.form.sourceCode)?.code
        }
        params.sourceCode = sourceCode

        let targetCodeList = []
        this.form.targetCodeList?.forEach((item) => {
          if (this.langList.some((it) => it.code == item)) {
            targetCodeList.push(item)
          } else {
            targetCodeList.push(this.langList.find((_) => _.name == item)?.code)
          }
        })
        params.targetCodeList = targetCodeList?.join(',')

        this.$refs.orderForm.validate(async (valid) => {
          if (valid) {
            await this.$http('stoneChangeOrderContent', params)
              .then(() => {
                this.disabled = true
                this.$emit('confirm')
                this.$emit('close', false)
              })
              .catch((error) => {
                this.$httpNotify(error)
              })
          }
        })
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
